@charset "utf-8";

// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

// Light mode colors
$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Dark mode colors
$text-color-dark:  #e0e0e0;
$background-color-dark: #242424;
$brand-color-dark: #66b3ff;
$grey-color-dark-theme: #a0a0a0;
$border-color-dark: #404040;
$code-background-dark: #2a2a2a;

// Width of the content area
$content-width:    800px;
$on-palm:          600px;
$on-laptop:        800px;

@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir`
@import
        "base",
        "layout",
        "syntax-highlighting";

// Theme toggle styles - keeping these after all imports to ensure they're not overridden
.theme-toggle {
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: 2px solid $grey-color-light;
    background: $background-color;
    color: $text-color;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 1000;
    font-size: 1.2rem;
    outline: none;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);

    &:hover {
        opacity: 0.9;
        transform: scale(1.05);
    }

    body[data-theme="dark"] & {
        border-color: $border-color-dark;
        background: $background-color-dark;
        color: $text-color-dark;
    }
}
