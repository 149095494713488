/**
 * Syntax highlighting styles
 */
 .highlight {
  background: #fff;
  @extend %vertical-rhythm;
  transition: background-color 0.3s ease;

  .highlighter-rouge & {
      background: #eef;
  }

  [data-theme="dark"] & {
      background: #2d2d2d;

      .highlighter-rouge & {
          background: #2d2d2d;
      }
  }

  // Light mode styles (existing)
  .c     { color: #998; font-style: italic } // Comment
  .err   { color: #a61717; background-color: #e3d2d2 } // Error
  .k     { font-weight: bold } // Keyword
  .o     { font-weight: bold } // Operator
  .cm    { color: #998; font-style: italic } // Comment.Multiline
  .cp    { color: #999; font-weight: bold } // Comment.Preproc
  .c1    { color: #998; font-style: italic } // Comment.Single
  .cs    { color: #999; font-weight: bold; font-style: italic } // Comment.Special
  .gd    { color: #000; background-color: #fdd } // Generic.Deleted
  .gd .x { color: #000; background-color: #faa } // Generic.Deleted.Specific
  .ge    { font-style: italic } // Generic.Emph
  .gr    { color: #a00 } // Generic.Error
  .gh    { color: #999 } // Generic.Heading
  .gi    { color: #000; background-color: #dfd } // Generic.Inserted
  .gi .x { color: #000; background-color: #afa } // Generic.Inserted.Specific
  .go    { color: #888 } // Generic.Output
  .gp    { color: #555 } // Generic.Prompt
  .gs    { font-weight: bold } // Generic.Strong
  .gu    { color: #aaa } // Generic.Subheading
  .gt    { color: #a00 } // Generic.Traceback
  .kc    { font-weight: bold } // Keyword.Constant
  .kd    { font-weight: bold } // Keyword.Declaration
  .kp    { font-weight: bold } // Keyword.Pseudo
  .kr    { font-weight: bold } // Keyword.Reserved
  .kt    { color: #458; font-weight: bold } // Keyword.Type
  .m     { color: #099 } // Literal.Number
  .s     { color: #d14 } // Literal.String
  .na    { color: #008080 } // Name.Attribute
  .nb    { color: #0086B3 } // Name.Builtin
  .nc    { color: #458; font-weight: bold } // Name.Class
  .no    { color: #008080 } // Name.Constant
  .ni    { color: #800080 } // Name.Entity
  .ne    { color: #900; font-weight: bold } // Name.Exception
  .nf    { color: #900; font-weight: bold } // Name.Function
  .nn    { color: #555 } // Name.Namespace
  .nt    { color: #000080 } // Name.Tag
  .nv    { color: #008080 } // Name.Variable
  .ow    { font-weight: bold } // Operator.Word
  .w     { color: #bbb } // Text.Whitespace
  .mf    { color: #099 } // Literal.Number.Float
  .mh    { color: #099 } // Literal.Number.Hex
  .mi    { color: #099 } // Literal.Number.Integer
  .mo    { color: #099 } // Literal.Number.Oct
  .sb    { color: #d14 } // Literal.String.Backtick
  .sc    { color: #d14 } // Literal.String.Char
  .sd    { color: #d14 } // Literal.String.Doc
  .s2    { color: #d14 } // Literal.String.Double
  .se    { color: #d14 } // Literal.String.Escape
  .sh    { color: #d14 } // Literal.String.Heredoc
  .si    { color: #d14 } // Literal.String.Interpol
  .sx    { color: #d14 } // Literal.String.Other
  .sr    { color: #009926 } // Literal.String.Regex
  .s1    { color: #d14 } // Literal.String.Single
  .ss    { color: #990073 } // Literal.String.Symbol
  .bp    { color: #999 } // Name.Builtin.Pseudo
  .vc    { color: #008080 } // Name.Variable.Class
  .vg    { color: #008080 } // Name.Variable.Global
  .vi    { color: #008080 } // Name.Variable.Instance
  .il    { color: #099 } // Literal.Number.Integer.Long

  // Dark mode styles
  [data-theme="dark"] & {
      .c, .cm, .c1, .cs   { color: #6a9955 } // Comments
      .err                 { color: #f44747; background-color: transparent } // Error
      .k, .kc, .kd, .kp, .kr { color: #569cd6 } // Keywords
      .o, .ow             { color: #b5cea8 } // Operator
      .cp                 { color: #cd8b00 } // Comment.Preproc
      .gd                 { color: #ff8383; background-color: #3b0000 }
      .gd .x              { color: #ff8383; background-color: #4b0000 }
      .ge                 { font-style: italic }
      .gr                 { color: #ff8383 }
      .gh                 { color: #88c0d0 }
      .gi                 { color: #a3be8c; background-color: #1b3d1b }
      .gi .x              { color: #a3be8c; background-color: #2c4c2c }
      .go                 { color: #888888 }
      .gp                 { color: #818181 }
      .gs                 { font-weight: bold }
      .gu                 { color: #88c0d0 }
      .gt                 { color: #ff8383 }
      .kt                 { color: #88c0d0 }
      .m, .mf, .mh, .mi, .mo, .il { color: #b5cea8 } // Numbers
      .s, .sb, .sc, .sd, .s2, .se, .sh, .si, .sx, .s1 { color: #ce9178 } // Strings
      .sr                 { color: #7bb359 } // Regex
      .ss                 { color: #d16d9e }
      .na                 { color: #88c0d0 }
      .nb                 { color: #4ec9b0 }
      .nc                 { color: #4ec9b0 }
      .no                 { color: #88c0d0 }
      .ni                 { color: #d16d9e }
      .ne                 { color: #ff8383 }
      .nf                 { color: #dcdcaa }
      .nn                 { color: #88c0d0 }
      .nt                 { color: #569cd6 }
      .nv                 { color: #88c0d0 }
      .w                  { color: #6a6a6a }
      .bp                 { color: #818181 }
      .vc, .vg, .vi      { color: #88c0d0 }
  }
}